import { styled } from "@mui/system";

const Select = styled("select")(({ theme }) => ({
  width: "200px",
  padding: theme.spacing(1),
  border: "2px solid black",
  borderRadius: theme.spacing(1),
  //   boxShadow: "0px 0px 25px rgba(0,0,0,0.2)",
  fontSize: "16px",
  fontFamily: "Inter",
  //   color: "#403884ff",
  fontWeight: "400",
  paddingRight: "30px", // Adjust the paddingRight value as needed
  // Add more space to the right edge of the select arrow
  backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M7 10l5 5 5-5z' /></svg>")`, // Add a custom arrow icon
  backgroundPosition: `calc(100% - 10px) center`, // Adjust the position of the arrow
  backgroundRepeat: "no-repeat",
  appearance: "none", // Remove the default arrow
  backgroundColor: "#ffffff00",
}));

export default Select;
