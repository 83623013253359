import React, { useState, useEffect } from "react";
import "./App.css";
import ProcessFlow from "./ProcessFlow";
import Container from "./components/Container";
import InputContainer from "./components/InputContainer";
import InputField from "./components/InputField";
import PrimaryButton from "./components/PrimaryButton";
import TreeViewContainer from "./components/TreeViewContainer";
import Select from "./components/Select";
import Dialogue from "./components/Dialogue";
import Login from "./components/Login";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { NumToString } from "./helper/formatting";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Tip from "./components/Tip";

import {
  useStoreApi,
  useReactFlow,
  ReactFlowProvider,
} from "react-flow-renderer";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#5925DC",
      light: "#6A36ED",
      dark: "#5925DC",
      contrastText: "#403884ff",
    },
  },
  typography: {
    fontFamily: [
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

function App() {
  const [model, setModel] = useState([]);
  const [visibleProcesses, setVisibleProcesses] = useState([]);
  const [product, setProduct] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [lciaData, setLciaData] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedIndicator, setSelectedIndicator] = useState("");
  const [selectedIndex, setSelectedIndex] = useState("0");
  // const [indicatorUnit, setindicatorUnit] = useState("");
  const [totalImpact, setTotalImpact] = useState(0);
  const [methods, setMethods] = useState([]);
  const [categories, setCategories] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState("0");
  const [menuState, setMenuState] = useState(0);
  const [dummyMode, setDummyMode] = useState(false);
  const [compactView, setCompactView] = useState(true);
  const [auth, setAuth] = useState(false);
  const [tip, setTip] = useState(1);

  // load local documents
  const demo = process.env.REACT_APP_DEMO === "true";

  // eslint-disable-next-line
  useEffect(() => {
    // Fetch data from the "/LICA" endpoint when the component mounts
    fetchLicaData();
    if (demo) setDummyMode(true);
    else setAuth(true);
    // eslint-disable-next-line
  }, []); // The empty dependency array ensures this runs once on mount

  // eslint-disable-next-line
  useEffect(() => {
    if (lciaData !== null && selectedIndicator !== "") {
      const indexItem = lciaData.find(
        (item) =>
          item.method === selectedMethod &&
          item.category === selectedCategory &&
          item.indicator === selectedIndicator
      );
      const index = indexItem ? indexItem : "0";

      setSelectedIndex(index);
      calcTotalImpact(model, index);
    }

    // eslint-disable-next-line
  }, [selectedIndicator]); // Add selectedStandard as a dependency to the effect

  // const apiUrl = "http://localhost:5000";
  const apiUrl = "http://192.168.2.36:5000";

  const fetchEstimation = () => {
    setIsLoading(true);
    setMenuState(1);
    setModel([]);
    handleTip();

    // Define the API endpoint
    const endpoint = dummyMode ? "/fake-estimate" : "/estimate";

    // Create the request body with the user input
    const requestBody = dummyMode ? {} : JSON.stringify({ product });

    callAPI(apiUrl, endpoint, requestBody);
  };

  const buildModel = async () => {
    setMenuState(0);
    fetchRun()
      .then(() => setIsLoading(false))
      .then(setSelectedComponent("1"));
    handleTip();
  };

  const fetchRun = async () => {
    const endpoint = dummyMode ? "/fake-run" : "/run";
    const requestBody = dummyMode ? {} : JSON.stringify({ model });
    return await callAPI(apiUrl, endpoint, requestBody);
  };

  const callAPI = async (url, endpoint, requestBody) => {
    const remoteEndpoint = url + endpoint;
    const localEndpoint = "/dummy-data/" + endpoint + ".json";

    if (demo) {
      return await fetch(localEndpoint)
        .then((response) => response.json())
        .then((data) => {
          setModel(data.answer);
          calcTotalImpact(data.answer, selectedIndex);
        })
        .catch((error) => {
          console.error("Error fetching", error);
          setModel([]);
        });
    } else {
      return await fetch(remoteEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: requestBody,
      })
        .then((response) => response.json())
        .then((data) => {
          setModel(data.answer);
          calcTotalImpact(data.answer, selectedIndex);
        })
        .catch((error) => {
          console.error("Error fetching", error);
          setModel([]);
        });
    }
  };

  useEffect(() => {
    // treeview selection changes
    filterProcesses();
    // eslint-disable-next-line
  }, [model, selectedComponent]);

  const fetchLicaData = () => {
    const remoteEndpoint = apiUrl + "/LICA";
    const localEndpoint = "/dummy-data/" + "LCIA.json";

    // Send a GET request to the API
    fetch(demo ? localEndpoint : remoteEndpoint)
      .then((response) => response.json())
      .then((data) => {
        setLciaData(data); // Save the response data in the licaData state

        // Extract unique methods, categories, and standards
        const uniqueMethods = [...new Set(data.map((item) => item.method))];
        setMethods(uniqueMethods);
        setSelectedMethod(uniqueMethods[0]);
        refreshCategories(data, uniqueMethods[0]);
      })
      .catch((error) => {
        console.error("Error fetching data from /LICA:", error);
      });
  };

  const handleMethodSelect = (event) => {
    // Get the selected method from the dropdown
    const selectedMethod = event.target.value;
    setSelectedMethod(selectedMethod);
    refreshCategories(lciaData, selectedMethod);
  };

  const refreshCategories = (data, newMethod) => {
    // Filter categories based on the selected method
    const filteredCategories = data
      .filter((item) => item.method === newMethod)
      .map((item) => item.category);
    setCategories([...new Set(filteredCategories)]); // Ensure unique categories

    // Find the first category for the selected method
    setSelectedCategory(filteredCategories[0]);
    refreshIndicators(data, newMethod, filteredCategories[0]);
  };

  const handleCategorySelect = (event) => {
    // Get the selected category from the dropdown
    const selectedCategory = event.target.value;
    setSelectedCategory(selectedCategory);
    refreshIndicators(lciaData, selectedMethod, selectedCategory);
  };

  const refreshIndicators = (data, newMethod, newCategory) => {
    // Filter indicators based on the selected category and method
    const filteredIndicators = data
      .filter(
        (item) => item.method === newMethod && item.category === newCategory
      )
      .map((item) => item.indicator);
    setIndicators([...new Set(filteredIndicators)]); // Ensure unique indicators

    // Reset the selected standard
    setSelectedIndicator(filteredIndicators[0]);
  };

  const handleIndicatorSelect = (event) => {
    setSelectedIndicator(event.target.value);
  };

  const calcTotalImpact = (model, index) => {
    console.log(index.index);
    let sum = 0;
    if (model.length !== 0 && model.processes.length !== 0) {
      sum = model.processes.reduce(
        (sum, item) =>
          sum +
          (item.impact != [] ? item.impact[index.index] * item.amount : 0),
        0
      );
    }
    setTotalImpact(sum);
    console.log("total impact: " + sum);
  };

  const filterProcesses = () => {
    if (model.length === 0) {
      setVisibleProcesses([]);
      return;
    }

    const hasChildren = model.tree.some(
      (childItem) =>
        childItem["parent-id"] &&
        selectedComponent &&
        childItem["parent-id"].toString() === selectedComponent.toString()
    );

    let res = [];
    if (hasChildren) {
      const allProcesses = getChildren(selectedComponent);
      const aggregatedProcesses = allProcesses.reduce((result, process) => {
        let stageId = process["stage-id"];

        if (
          !result[stageId] &&
          Object.values(result).some(
            (item) => item["stage-name"] === process["stage-name"]
          )
        ) {
          const existingItem = Object.values(result).find(
            (item) => item["stage-name"] === process["stage-name"]
          );
          stageId = existingItem["stage-id"];
        }

        if (!result[stageId]) {
          // If the stage ID doesn't exist in the result, create it with the initial impact value
          result[stageId] = {
            "stage-id": stageId,
            "stage-name": process["stage-name"],
            // impact: [...(process["impact"])],
            impact: [
              ...process["impact"].map((number) => number * process["amount"]),
            ],
            "process-id": stageId,
            "ecoinvent-process": "",
            "ecoinvent-loc": "",
            "ref-product": "",
            unit: "",
            amount: 1,
          };
        } else {
          // If the stage ID already exists, add the impact to the existing total
          result[stageId].impact = result[stageId].impact.map(
            (value, index) =>
              value + process["impact"][index] * process["amount"]
          );
        }
        return result;
      }, {});

      // Convert the aggregated object back to an array
      res = Object.values(aggregatedProcesses);
      console.log(res);
    } else {
      res = getChildren(selectedComponent);
    }

    setVisibleProcesses(res);
  };

  function getChildren(parentId) {
    console.log("getting children for: " + parentId);
    let processes = model.processes.filter(
      (item) => item["component-id"].toString() === parentId.toString()
    );
    const childProcesses = model.tree
      .filter((item) => item["parent-id"]?.toString() === parentId?.toString())
      .map((child) => getChildren(child.id, model))
      .flat(); // Flatten the array of arrays

    return processes.concat(childProcesses);
  }

  const handleTreeSelection = (e, selection) => {
    // console.log(selection);
    setSelectedComponent(selection);

    // fitView({ padding: 0.1 });
  };

  const handleTip = () => {
    setTip(tip + 1);
    console.log("increased tip");
  };

  return (
    <ThemeProvider theme={theme}>
      <ReactFlowProvider>
        {!auth && <Login handleAuth={() => setAuth(true)}></Login>}
        {auth && (
          <div className="App">
            {menuState === 1 && (
              <Dialogue
                handleClose={() => setMenuState(0)}
                handleBuildModel={() => buildModel()}
                tree={model.tree}
                params={model.params}
                showTip={demo}
              ></Dialogue>
            )}
            <div className="header">
              <div style={{ flexDirection: "row", display: "flex" }}>
                <h1 style={{ marginRight: "24px" }}>
                  <a
                    style={{ color: "black", textDecoration: "none" }}
                    href="/"
                  >
                    orbit
                  </a>
                  <span style={{ fontWeight: 300, fontSize: "14px" }}>
                    {" "}
                    MVP
                  </span>
                </h1>
                <FormGroup style={{ float: "left" }}>
                  <div className="tip-container">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={dummyMode}
                          disabled={demo}
                          onChange={() => setDummyMode(!dummyMode)}
                        />
                      }
                      label="dummy data"
                    />
                    {demo && tip === 1 && (
                      <Tip
                        number={1}
                        text="this demo uses pre-generated data"
                        action="next"
                        onClickHandler={handleTip}
                      />
                    )}
                  </div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={compactView}
                        onChange={() => setCompactView(!compactView)}
                      />
                    }
                    label="compact view"
                  />
                </FormGroup>
              </div>

              <div className="dropdowns">
                {totalImpact !== 0 && totalImpact !== undefined && (
                  <div className="totalImpacts">
                    <p>Total Impact</p>
                    <p>
                      <b>{NumToString(totalImpact)}</b> <br />
                      {selectedIndex.unitName}
                    </p>
                  </div>
                )}
                <div>
                  <p>Method</p>
                  <div className="tip-container">
                    <Select
                      onChange={handleMethodSelect}
                      value={selectedMethod}
                    >
                      {methods.map((method, index) => (
                        <option key={index} value={method}>
                          {method}
                        </option>
                      ))}
                    </Select>
                    {demo && tip === 5 && (
                      <Tip
                        number={5}
                        text="Select assessment method"
                        action="close"
                        onClickHandler={handleTip}
                      ></Tip>
                    )}
                  </div>
                </div>

                <div>
                  <p>Category</p>
                  <Select
                    onChange={handleCategorySelect}
                    value={selectedCategory}
                  >
                    {categories.map((category, index) => (
                      <option key={index} value={category}>
                        {category}
                      </option>
                    ))}
                  </Select>
                </div>

                <div>
                  <p>Standard</p>
                  <Select
                    onChange={handleIndicatorSelect}
                    value={selectedIndicator}
                  >
                    {indicators.map((indicator, index) => (
                      <option key={index} value={indicator}>
                        {indicator}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <Container>
              <InputContainer>
                <InputField
                  className="shadow"
                  placeholder="Enter product name"
                  // label="Enter product name"
                  value={dummyMode ? "model an electric scooter" : product}
                  onChange={(e) => setProduct(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <AttachFileIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "16px",
                      borderColor: "1px solid #ccc",
                      color: theme.palette.primary.main,
                      fontWeight: "600",
                    },
                  }}
                  // startAdornment={
                  //   <InputAdornment position="start">
                  //     test
                  //     <IconButton>
                  //       <CloudUploadIcon />
                  //     </IconButton>
                  //   </InputAdornment>
                  // }
                />
                <div className="tip-container">
                  <PrimaryButton
                    variant="contained"
                    onClick={() => fetchEstimation()}
                    disabled={isLoading}
                    className="shadow"
                  >
                    {isLoading ? "Loading..." : "Go"}
                  </PrimaryButton>
                  {demo && tip === 2 && (
                    <Tip number={2} text="Click 'Go' to start the simulation" />
                  )}
                </div>
              </InputContainer>
            </Container>

            <TreeViewContainer
              tree={!model.tree ? [] : model.tree}
              handleSelect={handleTreeSelection}
              treeSelection={[selectedComponent]}
              showTip={demo && tip === 4}
              handleTipClick={handleTip}
            ></TreeViewContainer>

            <ProcessFlow
              processes={!visibleProcesses ? [] : visibleProcesses}
              loading={isLoading}
              totalImpact={totalImpact}
              indicatorIndex={selectedIndex}
              compactView={compactView}
            />
          </div>
        )}
      </ReactFlowProvider>
    </ThemeProvider>
  );
}

export default App;
