import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";

const InputField = styled(TextField)(({ theme }) => ({
  width: "100%",
  // padding: theme.spacing(1.5),
  // border: "1px solid #ccc",
  borderRadius: "16px",
  fontSize: "16px",
  fontFamily: "Inter",
  color: theme.palette.primary.main,
  fontWeight: "600",
  "&::placeholder": {
    color: "#aaa", // Color for placeholder text
    fontWeight: "400",
  },
}));

export default InputField;
