import React from "react";
import { DataGrid } from "@mui/x-data-grid";

function JsonTable({ jsonData }) {
  if (!jsonData || jsonData.length === 0) {
    return <p>No data available.</p>;
  }

  const columns = Object.keys(jsonData[0]).map((header, index) => ({
    field: header,
    headerName: header,
    headerClassName: "custom-header",
    width: 150, // You can adjust the column width as needed
  }));

  const rows = jsonData.map((item, rowIndex) => ({
    id: rowIndex,
    ...item,
  }));

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        style={{ backgroundColor: "white" }}
        rows={rows}
        columns={columns}
        pageSize={5} // Number of rows per page
        checkboxSelection // Enable checkbox selection if needed
      />
    </div>
  );
}

export default JsonTable;
