import * as React from "react";

import PrimaryButton from "./PrimaryButton";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "1px solid #BBB",
  borderRadius: "10px",
  boxShadow: "0px 0px 25px #00000055",
  p: 4,
  textAlign: "left",
};

function Login(props) {
  // const [open, setOpen] = React.useState(true);
  // const handleClose = () => setOpen(false);
  // const handleOpen = () => setOpen(true);
  const [pw, setPw] = React.useState("");
  const [wrong, setWrong] = React.useState(false);

  const handleLogin = () => {
    if (pw == "orbit-demo") {
      props.handleAuth();
    } else {
      setWrong(true);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div>
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2>orbit demo </h2>
          <p>
            Please note that this application is just a demo.
            <br />
            <br /> It is not connected to our backend and uses a pregenerated
            dataset.
            <br />
            <br /> It is not designed for mobile use yet.
          </p>
          <TextField
            sx={{ width: "100%" }}
            error={wrong}
            value={pw}
            id="outlined-basic"
            label="Password"
            type="password"
            variant="outlined"
            onKeyPress={handleKeyPress} // Add this line to listen for key press
            onChange={(event) => {
              setPw(event.target.value);
              setWrong(false);
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              marginTop: "20px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <PrimaryButton onClick={handleLogin}>Login</PrimaryButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default Login;
