// import React from "react";
import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";

const TreeViewContainer = ({ tree, handleSelect }) => {
  // const [selected, setSelected] = React.useState<string[]>([]);
  const [selected, setSelected] = React.useState([]);

  function buildTree(data, parentId = -1) {
    const treeItems = data
      .filter((item) => item["parent-id"] === parentId)
      .map((item) => {
        const isAssembly = item.type === "assembly";
        const hasChildren = data.some(
          (childItem) => childItem["parent-id"] === item.id
        );
        return (
          <TreeItem
            key={item.id}
            nodeId={item.id.toString()}
            label={item.name}
            endIcon={isAssembly && !hasChildren ? null : undefined}
          >
            {buildTree(data, item.id)}
          </TreeItem>
        );
      });

    return treeItems;
  }

  const createTree = (data) => {
    if (!data || data == []) {
      return;
    }
    return (
      <TreeView
        aria-label="assembly navigator"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        // sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
        selected={selected}
        onNodeSelect={handleSelect}
      >
        {buildTree(data)}
      </TreeView>
    );
  };

  createTree(tree);

  return <div className="treeview">{createTree(tree)}</div>;
};

export default TreeViewContainer;
