import * as React from "react";

// import Button from "@mui/material/Button";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import TreeViewDisplay from "./TreeViewDisplay";
import Box from "@mui/material/Box";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
// import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import LinearProgress from "@mui/material/LinearProgress";

import JsonTable from "./JsonTable";

import Tip from "./Tip";

// import { Box } from "@mui/system";
// import Modal from "./Modal";
// import { Typography, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "white",
  border: "1px solid #BBB",
  borderRadius: "10px",
  boxShadow: "0px 0px 25px #00000055",
  p: 4,
  textAlign: "left",
};

// TODO: handle open state in App.js

function Dialogue(props) {
  // const [open, setOpen] = React.useState(true);
  // const handleClose = () => setOpen(false);
  // const handleOpen = () => setOpen(true);

  return (
    <div>
      <Modal
        open={true}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2>
            Modelling estimations{" "}
            <AutoFixHighIcon color="primary"></AutoFixHighIcon>
          </h2>
          <p>Check before generating the model</p>
          <div className="modal-area">
            <h4>Component structure</h4>
            {props.tree ? (
              <TreeViewDisplay
                tree={!props.tree ? [] : props.tree}
                handleSelect={() => {}}
              ></TreeViewDisplay>
            ) : (
              <LinearProgress />
            )}
          </div>
          <div className="modal-area">
            <h4>Main parameters</h4>
            {props.params ? (
              // <p>{JSON.stringify(props.params)}</p>
              <JsonTable jsonData={props.params} />
            ) : (
              <LinearProgress />
            )}
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <SecondaryButton onClick={() => {}} disabled={!props.tree}>
                Edit Estimations
              </SecondaryButton>
            </Box>
            <Box sx={{ m: 1 }} />
            <Box sx={{ display: "flex" }}>
              <div className="tip-container">
                <PrimaryButton
                  onClick={props.handleBuildModel}
                  disabled={!props.tree}
                >
                  Build model
                </PrimaryButton>
                {props.showTip && (
                  <Tip
                    number="3"
                    text="Click 'Build model' to generate the LCA"
                  ></Tip>
                )}
              </div>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default Dialogue;
