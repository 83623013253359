import { styled } from "@mui/system";

const PrimaryButton = styled("button")(({ theme }) => ({
  // width: "50%",
  padding: theme.spacing(2),
  border: "2px solid #5925DC",
  borderRadius: theme.spacing(2),
  backgroundColor: "#ffffff00",
  color: "#5925DC",
  transition: " 0.3s", // Add a smooth transition for the hover effect
  cursor: "pointer", // Change the cursor to a pointer when hovering
  fontSize: "16px",
  fontFamily: "Inter",
  fontWeight: "500",

  "&:hover": {
    borderColor: "#a097eaff",
    color: theme.palette.primary.light,
  },

  "&:disabled": {
    // backgroundColor: "#ccc",
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
  },
}));

export default PrimaryButton;
