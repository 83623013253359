// import React from "react";
import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";

import { useReactFlow } from "react-flow-renderer";

import Tip from "./Tip";

const TreeViewContainer = ({
  tree,
  handleSelect,
  treeSelection,
  showTip,
  handleTipClick,
}) => {
  const { fitView } = useReactFlow();

  const [defaultExpanded, setDefaultExpanded] = React.useState(["1"]);

  function buildTree(nodeData, parentId = -1) {
    return nodeData
      .filter((item) => item["parent-id"] === parentId)
      .map((item) => {
        const isAssembly = item.type === "assembly";
        const hasChildren = nodeData.some(
          (childItem) => childItem["parent-id"] === item.id
        );
        return (
          <TreeItem
            key={item.id}
            nodeId={item.id.toString()}
            label={item.name}
            endIcon={isAssembly && !hasChildren ? null : undefined}
            type={isAssembly ? "Assembly" : "Component"}
            className={isAssembly ? "tree-asm" : "tree-comp"}
          >
            {hasChildren && buildTree(nodeData, item.id)}
          </TreeItem>
        );
      });
  }

  const createTree = (data) => {
    if (!data || data == []) {
      return;
    }
    return (
      <div className="treeview-sidebar">
        <br />
        <div className="tip-container">
          <TreeView
            aria-label="assembly navigator"
            defaultCollapseIcon={<ExpandMoreIcon color="primary" />}
            defaultExpandIcon={<ChevronRightIcon color="primary" />}
            defaultExpanded={defaultExpanded}
            defaultSelected={["1"]}
            // sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
            selected={treeSelection}
            onNodeSelect={(event, node) => {
              fitView();
              handleSelect(event, node);
            }}
          >
            {buildTree(data)}
          </TreeView>
          {showTip && (
            <Tip
              number="4"
              text="Select components to explore their impacts"
              action="next"
              onClickHandler={handleTipClick}
            ></Tip>
          )}
        </div>
      </div>
    );
  };

  createTree(tree);

  // return <div className="treeview">{createTree(tree)}</div>;
  return createTree(tree);
};

export default TreeViewContainer;
