import { styled } from "@mui/system";

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center", // Center horizontally
  gap: "20px", // Adjust the gap as needed
  position: "absolute",
  bottom: "50px",
  left: 0, // Reset left to 0
  right: 0, // Reset right to 0
  margin: "0 auto", // Center horizontally within the page
  width: "400px",
});

export default Container;
