import React from "react";

const Tip = ({ text, number, action, onClickHandler }) => {
  return (
    <div className="tip-overlay">
      <div className="tip-number-container">{number}</div>
      <div className="tip-text">{text}</div>
      {action && (
        <a className="tip-button" onClick={onClickHandler}>
          {action}
        </a>
      )}
    </div>
  );
};

export default Tip;
