import React from "react";
import ReactFlow, { Controls } from "react-flow-renderer";
import CircularProgress from "@mui/material/CircularProgress";
import { NumToString } from "./helper/formatting";
import WidgetsRoundedIcon from "@mui/icons-material/WidgetsRounded";
import CarpenterRoundedIcon from "@mui/icons-material/CarpenterRounded";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import LocalShippingRoundedIcon from "@mui/icons-material/LocalShippingRounded";
import FaceRoundedIcon from "@mui/icons-material/FaceRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import BuildRoundedIcon from "@mui/icons-material/BuildRounded";
import AutoModeRoundedIcon from "@mui/icons-material/AutoModeRounded";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";

// import CustomNode from "./CustomNode";

// Define a custom node type without handles
const HeadlineNode = ({ data }) => {
  return <div style={data.style}>{data.label}</div>;
};

const nodeTypes = {
  // custom: CustomNode,
  headline: HeadlineNode,
};

const stageIcon = (stage) => {
  switch (stage) {
    case "Material Extraction":
      return <CarpenterRoundedIcon />;
    case "Production":
      return <ConstructionRoundedIcon />;
    case "Assembly":
      return <WidgetsRoundedIcon />;
    case "Distribution":
      return <LocalShippingRoundedIcon />;
    case "Use":
      return <FaceRoundedIcon />;
    case "End of Life":
      return <DeleteRoundedIcon />;
    case "Maintenance":
      return <ReplayRoundedIcon />;
    case "Refurbishment":
      return <AutoModeRoundedIcon />;
    case "Remanufacturing":
      return <BuildRoundedIcon />;
  }
};

const stagePos = (stage, compactView) => {
  const iX = 50;
  const iY = 40;
  const sX = 250;
  const sY = compactView ? 250 : 600;

  switch (stage) {
    case "Material Extraction":
      return { x: iX, y: iY };
    case "Production":
      return { x: iX + sX, y: iY };
    case "Assembly":
      return { x: iX + sX * 2, y: iY };
    case "Distribution":
      return { x: iX + sX * 3, y: iY };
    case "Use":
      return { x: iX + sX * 4, y: iY };
    case "End of Life":
      return { x: iX + sX * 5, y: iY };
    case "Maintenance":
      return { x: iX + sX * 4, y: iY - sY };
    case "Refurbishment":
      return { x: iX + sX * 3, y: iY - sY };
    case "Remanufacturing":
      return { x: iX + sX * 2, y: iY - sY };
  }
};

const ProcessFlow = ({
  processes,
  loading,
  totalImpact,
  indicatorIndex,
  compactView,
}) => {
  // Group processes by stage name
  const processesByStage = processes.reduce((acc, process) => {
    const stageName = process["stage-name"];
    if (!acc[stageName]) {
      acc[stageName] = [];
    }
    acc[stageName].push(process);
    return acc;
  }, {});

  // Create nodes for each process, grouped by stage, including headlines
  const nodes = [];
  let currentX = 50;

  Object.keys(processesByStage).forEach((stageName) => {
    const processesInStage = processesByStage[stageName];
    let pos = stagePos(stageName, compactView);

    // Create a headline node for the stage
    nodes.push({
      id: `${stageName}-headline`,
      type: "headline",
      data: {
        label: (
          <div>
            <p>{stageIcon(stageName)}</p>
            {/* <strong>Stage:</strong> {stageName} */}
            <strong>{stageName}</strong>
          </div>
        ),
      },
      style: {
        width: "200px",
        padding: "8px",
        // backgroundColor: "#000000", // Adjust the background color for the headline
        border: "none",
        borderRadius: "8px",
        // boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
        textAlign: "left",
        fontSize: "16px",
        color: "#AAA",
      },
      // position: { x: currentX, y: 40 }, // Adjust the vertical position of the headline
      position: pos,
      sourceHandle: false, // Remove input handle
      targetHandle: false, // Remove output handle
    });

    // Create a vertical stack of processes for each stage
    let backgroundColor = !loading ? "#ffffff" : "#ffffff55";
    let dy = 110;
    processesInStage.forEach((process) => {
      const processImpact =
        process["impact"] != 0 ? process["impact"][indicatorIndex.index] : 0;
      // console.log(processImpact);
      const relativeImpact = (processImpact / totalImpact) * process["amount"];
      // console.log(relativeImpact);
      const outlineWidth = relativeImpact * 20;
      const borderColor = processImpact > 0 ? "255,75,50" : "150,230,100";

      const label = nodes.push({
        id: process["process-id"].toString(),
        type: "default",
        data: {
          label: (
            <div>
              {compactView ? (
                <strong>{process["process-name"]}</strong>
              ) : (
                <>
                  <strong>Process Name:</strong> {process["process-name"]}
                  <br />
                  <hr />
                  <strong>Ecoinvent Process:</strong>{" "}
                  {process["ecoinvent-process"]}
                  <br />
                  <hr />
                  <strong>Location:</strong> {process["ecoinvent-loc"]}
                  <br />
                  <hr />
                  <strong>Reference Product:</strong> {process["ref-product"]}
                  <br />
                  <hr />
                  <strong>Unit:</strong> {process["unit"]}
                  <br />
                  <hr />
                  <strong>Amount:</strong> {process["amount"]}
                  <br />
                  <hr />
                </>
              )}
              <div style={{ color: "rgba(" + borderColor + ")" }}>
                {/* <strong>Stage Name:</strong> {process["stage-name"]} */}
                <strong>Impact:</strong>{" "}
                {NumToString(processImpact * process["amount"]) +
                  " " +
                  indicatorIndex.unitName}
              </div>
            </div>
          ),
        },
        style: {
          width: "200px",
          padding: "8px",
          backgroundColor: backgroundColor,
          border: "none",
          outline: "solid " + "rgba(" + borderColor + ",0.5)",
          outlineWidth: outlineWidth,
          borderRadius: "8px",
          boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
          textAlign: "left",
        },
        // position: { x: currentX, y: currentY },
        position: { x: pos.x, y: pos.y + dy },
      });
      dy += compactView ? 100 : 340; // Adjust the vertical spacing between processes
    });
  });

  const edges = processes
    .filter((_, index) => index < processes.length - 1)
    .map((_, index) => ({
      id: `edge-${index}`,
      source: processes[index]["process-id"].toString(), // Source node's process-id
      target: processes[index + 1]["process-id"].toString(), // Target node's process-id of the next stage
      type: "simplebezier",
      style: {
        stroke: "rgb(211, 199, 92)",
      },
    }));

  return (
    <div className="process-flow-container">
      {loading && <CircularProgress className="centered" color="primary" />}

      <ReactFlow nodes={nodes} edges={edges} nodeTypes={nodeTypes} fitView>
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default ProcessFlow;
