import { styled } from "@mui/system";

const InputContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "10px", // Adjust the gap between input and button
  width: "100%",
});

export default InputContainer;
